var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "voffice-popup-box",
    {
      staticClass: "full-xxs",
      attrs: { "trigger-id": _vm.triggerId, "apply-label": _vm.applyLabel },
      on: { closed: _vm.onClosed }
    },
    [
      _c(
        "table",
        { staticClass: "guestbox" },
        _vm._l(_vm.sections, function(l, what) {
          return _c("tr", { staticClass: "gb-row" }, [
            _c("td", { staticClass: "gb-td label" }, [
              _c("label", [_vm._v(_vm._s(_vm.getFieldLabel(what)))])
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "gb-td" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { disabled: !_vm.canDecr(what), type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.inc(what, -1)
                    }
                  }
                },
                [_vm._v("-")]
              )
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "gb-td" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { disabled: !_vm.canInc(what), type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.inc(what, 1)
                    }
                  }
                },
                [_vm._v("+")]
              )
            ])
          ])
        }),
        0
      ),
      _vm._v(" "),
      _vm.showing
        ? _c("div", { staticClass: "pet-notice-dlg" }, [
            _c("div", { staticClass: "box" }, [
              _c("div", { staticClass: "header" }, [
                _c("h4", [_vm._v("Hundehinweis")]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "close-setup", on: { click: _vm.okClick } },
                    [_c("i", { staticClass: "fas fa-times" })]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "body" }, [
                _c("div", [
                  _vm._v('"Mehr Hunde? Bitte '),
                  _c("a", { attrs: { href: "./kontakt" } }, [
                    _vm._v('kontaktieren Sie uns"')
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.okClick }
                  },
                  [_vm._v("OK")]
                )
              ])
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }